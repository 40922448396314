import { Col, Row } from 'antd';
import style from './result-step.module.scss';
import party from "../images/objects.svg";
import check from "../images/check.svg";
import PrimaryButton from '../components/button';


interface ResultStepProps {
  result?:any
}
const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
  event.preventDefault();
  const url = 'https://app.adjust.com/1c1ehqy6';
  window.open(url, '_blank');
};

function ResultStep(props: ResultStepProps) {
  const highlightKidokit = (text:string) => {
    const phrasesToHighlight = [
      "Pediatrician is available on Kidokit",
      "Download now!",
      "Pediatrician and Dietician are available on Kidokit",
      "over 1000 games and activity videos",
      "Tracking development is easy with Kidokit!",
      "Ask to Expert"
    ];

    let newText = text;
    phrasesToHighlight.forEach(phrase => {
      const regex = new RegExp(`(${phrase})`, 'gi');
      newText = newText.replace(regex, '<b>$1</b>');
    });

    return <span dangerouslySetInnerHTML={{ __html: newText }} />;
  };

  return (
    <Row gutter={[24, 24]} className={style.container}>
      <div className={style.resultContainer}>
        <Row  className={style.resultInner}gutter={[24,0]}>
          <Col>
          <img src={party} className={style.party} alt="party" /> 
          </Col>
          <Col>
            <p>Congratulations! A personalized development <br/>
            plan for your child is ready.</p>
          </Col>
          <Col className={style.desc}>
            <Row >
              <Col span={24} className={style.text}>
              <img src={check}  alt="party" /> <span>{highlightKidokit(props.result.desc)}</span>
              </Col>
              <Col span={24}className={style.text}>
                  <img src={check}  alt="party" /><b>Daily Plan</b>
              </Col>
              <Col span={24}className={style.text}>
                  <img src={check}  alt="party" /> 1000+ <b> play and activity </b> videos
              </Col>
              <Col span={24} className={style.text}>
                 <img src={check}  alt="party" /> <b>Weekly Articles</b> for children up to 3 years old
              </Col>
              <Col span={24} className={style.text}> 
                  <img src={check}  alt="party" /> <b> Monthly Articles </b> for children aged 3-6
              </Col>
              <Col span={24} className={style.text}>
                 <img src={check}  alt="party" /><b >Expert assessment videos </b> 
              </Col>
              <Col>And much more...</Col>
            </Row>
          </Col>
          <Col className={style.qr}><img alt='kidokit-qr' src={require('../images/quiz.png')} ></img></Col>
          <Col className={style.button}> <PrimaryButton onClick={handleButtonClick}>Download now!</PrimaryButton></Col>
        </Row>
        <span></span>
      </div>
    </Row>
  );
}

export default ResultStep;
