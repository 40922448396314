export const questions =[
    {
        id:"0-3 months",
        question:[
            {
                id:1,
                type:'emoji',
                text:"Does your baby make sounds on their own?",
                answers:[1,2,3]
            },
            {
                id:2,
                type:'emoji',
                text:"Does your baby show a sucking reflex and latch onto the breast comfortably?",
                answers:[1,2,3]
            },
            {
                id:3,
                type:'emoji',
                text:"Is your baby’s sleep regular?",
                answers:[1,2,3]
            },
            {
                id:4,
                type:'card',
                text:"What is the most challenging aspect of caring for your baby?",
                answers:[4,5,6,7,8,9]
            }, 
        ]
    },
    {
        id:"3-6 months",
        question:[
            {
                id:1,
                type:'emoji',
                text:"Does your baby smile or laugh?",
                answers:[1,2,3]
            },
            {
                id:2,
                type:'emoji',
                text:"Does your baby follow moving sounds?",
                answers:[1,2,3]
            },
            {
                id:3,
                type:'emoji',
                text:"Does your baby put objects in their mouth?",
                answers:[1,2,3]
            },
            {
                id:4,
                type:'emoji',
                text:"Is your baby’s sleep regular?",
                answers:[1,2,3]
            },
            {
                id:5,
                type:'card',
                text:"What is the most challenging aspect of caring for your baby?",
                answers:[4,5,6,7,8,9]
            },
        ]
    },
    {
        id:"6-9 months",
        question:[
            {
                id:1,
                type:'emoji',
                text:"Can your baby sit without support?",
                answers:[1,2,3]
            },
            {
                id:2,
                type:'emoji',
                text:"Is your baby crawling?",
                answers:[1,2,3]
            },
            {
                id:3,
                type:'emoji',
                text:"Does your baby explore objects with their mouth?",
                answers:[1,2,3]
            },
            {
                id:4,
                type:'emoji',
                text:"Is your baby’s sleep regular?",
                answers:[1,2,3]
            },
            {
                id:5,
                type:'emoji',
                text:"Does your baby show excitement when seeing mom or dad?",
                answers:[1,2,3]
            },
            {
                id:6,
                type:'emoji',
                text:"Can your baby make eye contact?",
                answers:[1,2,3]
            },
            {
                id:7,
                type:'emoji',
                text:"Does your baby make sounds to attract attention?",
                answers:[1,2,3]
            },
            {
                id:8,
                type:'card',
                text:"What is the most challenging aspect of caring for your baby?",
                answers:[4,5,10,7,8,9]
            },
        ]
    },
    {
        id:"9-12 months",
        question:[
            {
                id:1,
                type:'emoji',
                text:"Does your baby deliberately drop toys and watch them fall?",
                answers:[1,2,3]
            },
            {
                id:2,
                type:'emoji',
                text:"Is your baby’s sleep regular?",
                answers:[1,2,3]
            },
            {
                id:3,
                type:'emoji',
                text:"Can your baby sit for long periods without support?",
                answers:[1,2,3]
            },
            {
                id:4,
                type:'emoji',
                text:"Does your baby recognize their name and turn towards you when called?",
                answers:[1,2,3]
            },
            {
                id:5,
                type:'emoji',
                text:"Does your baby wave goodbye?",
                answers:[1,2,3]
            },
            {
                id:6,
                type:'emoji',
                text:"Can your baby make eye contact?",
                answers:[1,2,3]
            },
            {
                id:7,
                type:'emoji',
                text:"Can your baby transfer toys from one hand to another?",
                answers:[1,2,3]
            },
            {
                id:8,
                type:'card',
                text:"What is the most challenging aspect of caring for your baby?",
                answers:[4,5,10,7,8,9]
            },
        ]
    },
    {
        id:"12-15 months",
        question:[
            {
                id:1,
                type:'emoji',
                text:"Can your child put toys back in their box?",
                answers:[1,2,3]
            },
            {
                id:2,
                type:'emoji',
                text:"Is your child’s sleep regular?",
                answers:[1,2,3]
            },
            {
                id:3,
                type:'emoji',
                text:"Can your child touch different textures without discomfort?",
                answers:[1,2,3]
            },
            {
                id:4,
                type:'emoji',
                text:"Can your child wave goodbye?",
                answers:[1,2,3]
            },
            {
                id:5,
                type:'emoji',
                text:"Would you say that your child has a poor appetite?",
                answers:[1,2,3]
            },
            {
                id:6,
                type:'emoji',
                text:"Can your child identify or point to objects when asked? (e.g., “Where is your nose?”)",
                answers:[1,2,3]
            },
            {
                id:7,
                type:'card',
                text:"What is the most challenging aspect of caring for your child?",
                answers:[4,5,8,11,12,13]
            },
        ]
    },
    {
        id:"15-18 months",
        question:[
            {
                id:1,
                type:'emoji',
                text:"Can your child identify five body parts when asked?",
                answers:[1,2,3]
            },
            {
                id:2,
                type:'emoji',
                text:"Can your child put on and take off their shoes?",
                answers:[1,2,3]
            },
            {
                id:3,
                type:'emoji',
                text:"Is your child’s sleep regular?",
                answers:[1,2,3]
            },
            {
                id:4,
                type:'emoji',
                text:"Would you say your child has a poor appetite?",
                answers:[1,2,3]
            },
            {
                id:5,
                type:'emoji',
                text:"Can your child drink water without help?",
                answers:[1,2,3]
            },
            {
                id:6,
                type:'emoji',
                text:"Can your child mimic some animal sounds?",
                answers:[1,2,3]
            },
            {
                id:7,
                type:'card',
                text:"What is the most challenging aspect of caring for your child?",
                answers:[4,5,8,11,12,13]
            },
        ]
    },
    {
        id:"18-21 months",
        question:[
            {
                id:1,
                type:'emoji',
                text:"Can your child kick a ball?",
                answers:[1,2,3]
            },
            {
                id:2,
                type:'emoji',
                text:"Is your child’s sleep regular?",
                answers:[1,2,3]
            },
            {
                id:3,
                type:'emoji',
                text:"Can your child understand simple commands like “Give me the shoe”?",
                answers:[1,2,3]
            },
            {
                id:4,
                type:'emoji',
                text:"Can your child climb stairs with support or independently?",
                answers:[1,2,3]
            },
            {
                id:5,
                type:'emoji',
                text:"Does your child try to identify objects in books?",
                answers:[1,2,3]
            },
            {
                id:6,
                type:'emoji',
                text:"Would you say your child has a poor appetite?",
                answers:[1,2,3]
            },
            {
                id:7,
                type:'card',
                text:"What is the most challenging aspect of caring for your child?",
                answers:[4,5,8,11,12,13]
            },
        ]
    },
    {
        id:"21-24 months",
        question:[
            {
                id:1,
                type:'emoji',
                text:"Can your child sort objects by size?",
                answers:[1,2,3]
            },
            {
                id:2,
                type:'emoji',
                text:"Is your child’s sleep regular?",
                answers:[1,2,3]
            },
            {
                id:3,
                type:'emoji',
                text:"Can your child touch art supplies without fear of getting dirty?",
                answers:[1,2,3]
            },
            {
                id:4,
                type:'emoji',
                text:"Can your child build a tower with 4-6 blocks?",
                answers:[1,2,3]
            },
            {
                id:5,
                type:'emoji',
                text:"Do you observe signs of the “terrible twos”?",
                answers:[1,2,3]
            },
            {
                id:6,
                type:'emoji',
                text:"Can your child verbally name objects, animals, and people?",
                answers:[1,2,3]
            },
            {
                id:7,
                type:'card',
                text:"What is the most challenging aspect of caring for your child?",
                answers:[4,5,8,11,12,13]
            },
        ]
    },
    {
        id:"24-30 months",
        question:[
            {
                id:1,
                type:'emoji',
                text:"Can your child play noisy games without being bothered?",
                answers:[1,2,3]
            },
            {
                id:2,
                type:'emoji',
                text:"Can your child tidy up after themselves?",
                answers:[1,2,3]
            },
            {
                id:3,
                type:'emoji',
                text:"Can your child put on and take off their shoes by themselves?",
                answers:[1,2,3]
            },
            {
                id:4,
                type:'emoji',
                text:"Is your child’s sleep regular?",
                answers:[1,2,3]
            },
            {
                id:5,
                type:'emoji',
                text:"Do you observe signs of the “terrible twos”?",
                answers:[1,2,3]
            },
            {
                id:6,
                type:'emoji',
                text:"Do you think your child has a poor appetite?",
                answers:[1,2,3]
            },
            {
                id:7,
                type:'emoji',
                text:"Is your child showing signs of being ready for toilet training?",
                answers:[1,2,3]
            },
            {
                id:8,
                type:'card',
                text:"What is the most challenging aspect of caring for your child?",
                answers:[5,8,9,11,12,13]
            },
        ]
    },
    {
        id:"30-36 months",
        question:[
            {
                id:1,
                type:'emoji',
                text:"Can your child climb stairs alone?",
                answers:[1,2,3]
            },
            {
                id:2,
                type:'emoji',
                text:"Can your child touch various materials without discomfort? (e.g., playdough, paint, shaving foam)",
                answers:[1,2,3]
            },
            {
                id:3,
                type:'emoji',
                text:"Can your child play independently?",
                answers:[1,2,3]
            },
            {
                id:4,
                type:'emoji',
                text:"Is your child toilet-trained?",
                answers:[1,2,3]
            },
            {
                id:5,
                type:'emoji',
                text:"Is your child’s sleep regular?",
                answers:[1,2,3]
            },
            {
                id:6,
                type:'emoji',
                text:"Do you think your child has a poor appetite?",
                answers:[1,2,3]
            },
            {
                id:7,
                type:'card',
                text:"What is the most challenging aspect of caring for your child?",
                answers:[5,8,9,11,12,13]
            },
        ]
    },
    {
        id:"36-48 months",
        question:[
            {
                id:1,
                type:'emoji',
                text:"Can your child count to 10?",
                answers:[1,2,3]
            },
            {
                id:2,
                type:'emoji',
                text:"Is your child’s sleep regular?",
                answers:[1,2,3]
            },
            {
                id:3,
                type:'emoji',
                text:"Can your child stand on one foot for 5 seconds?",
                answers:[1,2,3]
            },
            {
                id:4,
                type:'emoji',
                text:"Can your child say their name, surname, gender, and age?",
                answers:[1,2,3]
            },
            {
                id:5,
                type:'emoji',
                text:"Is your child’s speech understandable?",
                answers:[1,2,3]
            },
            {
                id:6,
                type:'emoji',
                text:"Can your child use a fork and spoon skillfully?",
                answers:[1,2,3]
            },
            {
                id:7,
                type:'emoji',
                text:"Is your child very active?",
                answers:[1,2,3]
            },
            {
                id:8,
                type:'card',
                text:"What is the most challenging aspect of caring for your child?",
                answers:[5,8,9,11,12,13]
            },
        ]
    },
    {
        id:"48-60 months",
        question:[
            {
                id:1,
                type:'emoji',
                text:"Can your child count objects up to 5 by touching them?",
                answers:[1,2,3]
            },
            {
                id:2,
                type:'emoji',
                text:"Can your child put on and take off their clothes?",
                answers:[1,2,3]
            },
            {
                id:3,
                type:'emoji',
                text:"Is your child’s sleep regular?",
                answers:[1,2,3]
            },
            {
                id:4,
                type:'emoji',
                text:"Can your child focus on a single activity for more than 5 minutes?",
                answers:[1,2,3]
            },
            {
                id:5,
                type:'emoji',
                text:"Is your child’s speech fully understandable?",
                answers:[1,2,3]
            },
            {
                id:6,
                type:'emoji',
                text:"Is your child very active?",
                answers:[1,2,3]
            },
            {
                id:7,
                type:'emoji',
                text:"Can your child recognize and name basic geometric shapes?",
                answers:[1,2,3]
            },
            {
                id:8,
                type:'card',
                text:"What is the most challenging aspect of caring for your child?",
                answers:[5,8,9,11,12,13]
            },
        ]
    },
    {
        id:"60-72 months",
        question:[
            {
                id:1,
                type:'emoji',
                text:"Can your child identify where you touched them with their eyes closed?",
                answers:[1,2,3]
            },
            {
                id:2,
                type:'emoji',
                text:"Does your child play well with friends?",
                answers:[1,2,3]
            },
            {
                id:3,
                type:'emoji',
                text:"Can your child eat without spilling food?",
                answers:[1,2,3]
            },
            {
                id:4,
                type:'emoji',
                text:"Is your child’s sleep regular?",
                answers:[1,2,3]
            },
            {
                id:5,
                type:'emoji',
                text:"Can your child focus on a single activity for more than 5 minutes?",
                answers:[1,2,3]
            },
            {
                id:6,
                type:'emoji',
                text:"Is your child very active?",
                answers:[1,2,3]
            },
            {
                id:7,
                type:'emoji',
                text:"Can your child copy letters and shapes?",
                answers:[1,2,3]
            },
            {
                id:8,
                type:'emoji',
                text:"Does your child have a preference for using their right or left hand?",
                answers:[1,2,3]
            },
            {
                id:9,
                type:'card',
                text:"What is the most challenging aspect of caring for your child?",
                answers:[5,8,9,11,12,13]
            },
        ]
    },
    
]
export const  answers = [
    {
        id:1,
        icon:'😊',
        title:"Yes"
    },
    {
        id:2,
        icon:'😕',
        title:"No"
    },
    {
        id:3,
        icon:'🤔',
        title:"Not sure"
    },
    {
        id:4,
        icon:'emzirme',
        title:"Breastfeeding"
    },
    {
        id:5,
        icon:'uyku',
        title:"Sleep"
    },
    {
        id:6,
        icon:'growth',
        title:"Creating a Routine"
    },
    {
        id:7,
        icon:'oyun',
        title:"Activities"
    },
    {
        id:8,
        icon:'gelisim',
        title:"Development tracking"
    },
    {
        id:9,
        icon:'health',
        title:"Health"
    },
    {
        id:10,
        icon:'beslenme',
        title:"Supplementary food"
    },
    {
        id:11,
        icon:'beslenme',
        title:"Feeding"
    },
    {
        id:12,
        icon:'davranis',
        title:"Behaviors"
    },
    {
        id:13,
        icon:'oyun',
        title:"Games and activities"
    },
]
export const results =[
    {
        id:4,
        title:"breastfeeding",
        desc:"Pediatrician is available on Kidokit to answer your questions about breastfeeding! You can also consult our expert team on all other topics. Download now!"
    },
    {
        id:5,
        title:"sleep",
        desc:"Pediatrician is available on Kidokit to answer your questions about sleep! You can also consult our expert team on all other topics. Download now!"
    },
    {
        id:6,
        title:"creating a routine",
        desc:"Pediatrician is available on Kidokit to answer your questions about creating routines! You can also consult our expert team on all other topics. Download now!"
    },{
        id:7,
        title:"activities",
        desc:"Kidokit has over 1000 games and activity videos. Start your daily plan now by downloading Kidokit!"
    },{
        id:8,
        title:"development tracking",
        desc:"Tracking development is easy with Kidokit! With videos of activities prepared by our experts, development tracking questions, and exclusive expert assessment questions, download Kidokit now!"
    },{
        id:9,
        title:"health",
        desc:"Pediatrician is available on Kidokit to answer your questions about health! You can also consult our expert team on all other topics. Download now!"
    },{
        id:10,
        title:"Solid Foods",
        desc:"Pediatrician is available on Kidokit to answer your questions about solid foods! You can also consult our expert team on all other topics. Download now!"
    },
    {
        id:11,
        title:"feeding",
        desc:"Pediatrician and Dietician are available on Kidokit to answer your questions about feeding! You can also consult our expert team on all other topics. Download now!"
    },
    {
        id:12,
        title:"behaviors",
        desc:"Kidokit has Ask to Expert feature! You can consult our expert team on all topics. Download Kidokit now!"
    },
    {
        id:13,
        title:"games and activities",
        desc:"Kidokit has over 1000 games and activity videos. Start your daily plan now by downloading Kidokit!"
    },
    
]